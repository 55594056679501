//Montserrat
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

// Cinzel
@import url('https://fonts.googleapis.com/css2?family=Cinzel:wght@400;700&display=swap');

//Proxima
@font-face {
  font-family: 'Proxima';
  src: url('/fonts/Proxima/ProximaNova.woff2');
  font-display: swap;
}

@font-face {
  font-family: 'ProximaSemiBold';
  src: url('/fonts/Proxima/proxima-nova-semibold.ttf');
  font-weight: 600;
}

//Proxima
@font-face {
  font-family: 'ProximaLight';
  src: url('/fonts/Proxima/Proxima-Nova-Light.woff2');
  font-display: swap;
}

//Proxima Thin
@font-face {
  font-family: 'ProximaThin';
  src: url('/fonts/Proxima/proxima-nova-thin.woff2');
  font-display: swap;
}

//Geometos
@font-face {
  font-family: 'Geometos';
  src: url('/fonts/Geometos/Geometos.woff2');
  font-display: swap;
}

//HastaGrotesk
@font-face {
  font-family: 'HastaGrotesk';
  src: url('/fonts/HastaGrotesk/hastagrotesk.woff2');
  font-display: swap;
}

//KeepCalm
@font-face {
  font-family: 'KeepCalm';
  src: url('/fonts/KeepCalm/KeepCalm-Medium.ttf');
  font-display: swap;
}
