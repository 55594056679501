.BackgroundTransitionIn {
  display: none;
  pointer-events: none;
  position: absolute;
  inset: 0;

  &__active {
    display: block;
    pointer-events: auto;
  }
}

.BackgroundTransitionOut {
  display: none;
  pointer-events: none;
  position: absolute;
  inset: 0;

  &__active {
    display: block;
    pointer-events: auto;
  }
}
