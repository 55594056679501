@import 'mixins.scss';
:root {
  /* FONT */
  //Family
  --ff-title: 'KeepCalm', 'serif';
  --ff-default: 'Proxima', 'sans-serif';
  --ff-thin: 'ProximaThin', 'sans-serif';
  --ff-light: 'ProximaLight', 'sans-serif';

  //Size
  --fz-xs: 12px;
  --fz-sm: 16px;
  --fz-md: 24px;
  --fz-lg: 36px;
  --fz-xl: 48px;
  --fz-xxl: 60px;

  @include small {
    --fz-xs: 0.8em;
    --fz-sm: 1em;
    --fz-md: 1.2em;
    --fz-lg: 1.7em;
    --fz-xl: 2em;
    --fz-xxl: 2.6em;
  }

  @include xs {
    font-size: 12px;
  }

  //Weight
  --fw-normal: 400;
  --fw-bold: 700;

  // Letter-spacing
  --ls-tight: -0.05em;
  --ls-normal: 0em;
  --ls-wide: 0.05em;

  /* COLOR */
  //TODO: Mixin for alpha

  //Title
  --h2-color: #635eb5;

  //Blue
  --blue: rgba(106, 154, 224, 1);
  --blue-50: rgba(106, 154, 224, 0.5);
  --blue-20: rgba(106, 154, 224, 0.8);

  //Purple
  --purple: rgba(92, 37, 141, 1);
  --purple-50: rgba(92, 37, 141, 0.5);
  --purple-20: rgba(92, 37, 141, 0.2);

  //Gradient
  --gradient: linear-gradient(rgba(106, 154, 224, 1), rgba(92, 37, 141, 1));
  --gradient-left: linear-gradient(
    to left,
    rgba(106, 154, 224, 1),
    rgba(92, 37, 141, 1)
  );
  --gradient-title: linear-gradient(rgba(52, 76, 112, 1), rgba(46, 18, 70, 1));
  /*linear-gradient(rgba(26, 38, 56, 1), rgba(23, 9, 35, 1));*/
  --gradient-50: linear-gradient(
    rgba(106, 154, 224, 0.5),
    rgba(92, 37, 141, 0.5)
  );
  --gradient-20: linear-gradient(
    rgba(106, 154, 224, 0.2),
    rgba(92, 37, 141, 0.2)
  );
  --gradient-10: linear-gradient(
    rgba(106, 154, 224, 0.1),
    rgba(92, 37, 141, 0.1)
  );

  //Grey
  --lightGrey: #d6d6d6;
  --grey: #b1b1b1;
  --darkGrey: #666666;

  --mobile-breakpoint: 800px;

  --ext-padding: 25px;
  --in-padding: 20px;

  --tot-padding: calc(var(--ext-padding) + var(--in-padding));

  --separ-padding: 80px;

  @include small {
    --ext-padding: 20px;
    --in-padding: 20px;
    --tot-padding: calc(var(--ext-padding) + var(--in-padding));
  }

  @media (max-width: 600px) {
    --ext-padding: 15px;
    --in-padding: 15px;
    --tot-padding: calc(var(--ext-padding) + var(--in-padding));
  }

  --header-height: 32px;
}
