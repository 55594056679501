@import './reset';
@import './variable';
@import './fonts';
@import './layout';
@import './mixins';

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

h1,
h2,
h2,
h4,
h5,
h6,
p,
a,
ul,
li,
ol,
span {
  font-family: var(--ff-default);
  font-weight: var(--fw-normal);
  font-size: var(--fz-sm);
  letter-spacing: 1px;
}

p {
  margin-bottom: 0.5em;

  * {
    font-family: inherit;
    font-size: inherit;
  }

  a {
    color: #6e85cf;
  }

  b {
    font-family: 'ProximaSemiBold';
  }
}

body {
  background: linear-gradient(rgba(106, 154, 224, 1), rgba(92, 37, 141, 1) 73%);
}

@supports (-webkit-touch-callout: none) {
  /* CSS specific to iOS devices */
  body {
    overscroll-behavior: none;
  }
}

//OVERLAY
#btn-menu {
  position: fixed;
  top: calc(var(--ext-padding) + var(--in-padding));
  right: calc(var(--ext-padding) + var(--in-padding));
  width: 90px;
  height: 40px;
  z-index: 99;
  cursor: pointer;
}

//
.tag-green {
  background: #7cdbd7;
}

.tag-blue {
  background: #65aff9;
}

.tag-purple {
  background: #8659fa;
}

.tag-default {
  background: #65aff9;
}

//
.tag-fc-green {
  color: #7cdbd7 !important;
}

#footerMenu {
  position: fixed;
  z-index: 8;
  top: var(--ext-padding);
  right: var(--ext-padding);
  opacity: 0;

  @include small {
    display: none;
  }
}

.nav {
  position: fixed;
  top: var(--ext-padding);
  left: var(--ext-padding);
  right: var(--ext-padding);
  height: 100px;
  z-index: 2;
}

/* etourisme */
.etourisme {
  font-family: 'Montserrat', sans-serif;
}
.etourisme-btn {
  padding-top: calc(150px - var(--tot-padding)) !important;
  padding-bottom: 150px !important;
  margin-top: -7vh;

  @include small {
    margin-top: -6vh !important;
    padding: 12vh 0 18vh 0 !important;
  }

  .etourisme-btn-bg:hover,
  .anim .etourisme-btn-bg {
    background: linear-gradient(150deg, #55d9ff, #009cff);
  }
}
/**/

/* TarteAuCitron */
#tarteaucitronRoot {
  #tarteaucitronAlertBig {
    width: 530px !important;
    bottom: 3vw !important;
    left: 3vw !important;
    background: #fff !important;
    box-shadow: 0px 15px 20px rgba(0, 0, 0, 15%) !important;
    padding: 20px !important;
    display: flex !important;
    justify-content: space-between !important;
    flex-wrap: wrap !important;

    &[style*='display: none'] {
      display: none !important;
    }

    #tarteaucitronDisclaimerAlert {
      padding: 0 !important;
      width: 100% !important;
      color: #000;
      margin-bottom: 50px;
      position: relative;
      margin-left: 70px;
      font-size: var(--fz-sm) !important;
      color: var(--darkGrey);

      &:after {
        content: url('/img/svg/cookie.svg');
        position: absolute;
        width: 45px;
        height: 45px;
        left: -60px;
        top: 0;
      }
    }

    button {
      margin: 0 !important;
      padding: 10px 25px;
    }

    .tarteaucitronAllow {
      background: var(--gradient-left) !important;
      color: #fff;

      .tarteaucitronCheck {
        display: none;
      }
    }

    .tarteaucitronDeny {
      background: var(--gradient-left) !important;
      position: relative;

      &:before {
        content: '';
        position: absolute;
        width: calc(100% - 2px);
        height: calc(100% - 2px);
        left: 1px;
        top: 1px;
        background: #fff;
      }

      &:after {
        content: 'Tout refuser';
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        background: var(--gradient-left);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

        display: table; /* fix for Firefox */
      }

      .tarteaucitronCross {
        display: none;
      }
    }

    #tarteaucitronCloseAlert {
      border: 1px solid var(--darkGrey);
      position: relative;
      color: var(--darkGrey);
    }

    @include small {
      width: 80vw !important;
      bottom: calc(var(--ext-padding) + var(--in-padding)) !important;
      left: calc(var(--ext-padding) + var(--in-padding)) !important;
      padding: var(--in-padding) !important;
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 15%) !important;

      #tarteaucitronDisclaimerAlert {
        margin-bottom: 0;
      }
      button {
        width: 100%;
        margin-top: var(--in-padding) !important;
      }
    }
  }
}

#tarteaucitron {
  #tarteaucitronClosePanel {
    background: var(--blue) !important;
  }

  .tarteaucitronBorder {
    border: none !important;
  }

  #tarteaucitronServices {
    .tarteaucitronMainLine {
      background: var(--gradient) !important;
      border: none !important;

      #tarteaucitronInfo {
        background: transparent !important;
        border: none !important;
      }
    }
  }
}

#tarteaucitronManager {
  position: relative;

  img {
    display: none;
  }

  &:after {
    content: url('/img/svg/cookie.svg');
    position: absolute;
    width: 35px;
    height: 35px;
    left: var(--tot-padding);
    bottom: var(--tot-padding);
  }

  @include small {
    img {
      display: none;
    }

    &:after {
      width: 7vw;
      height: 7vw;
      left: calc(var(--ext-padding) + 4px);
      bottom: calc(var(--ext-padding) + 4px);
    }
  }
}

#tarteaucitronIcon {
  left: 0;
  z-index: 99 !important;
}
/* */

.mapboxgl-ctrl-bottom-right {
  display: none;
}

.mapboxgl-ctrl-bottom-left {
  display: none;
}

.mapboxgl-marker {
  z-index: 3;
}

#form {
  background: #fafafa;
}

:target {
  display: block;
  position: relative;
  top: -100px;
  visibility: hidden;
}

.menu-other-content {
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.Custom404 {
  background: #fff;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    font-family: var(--ff-default);
    font-weight: var(--fw-normal);
    font-size: var(--fz-md);
    color: var(--purple);
  }
}

.gemme {
  width: 100%;
  height: 85%;
  position: absolute;
  left: 50%;
  top: 52%;
  transform: translate(-50%, -50%);
  z-index: 2;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  @include small {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 75%;
      height: 75%;
      object-fit: contain;
    }
  }
}

.afterCarousel {
  margin-top: -130px;

  @include small {
    margin-top: 0;
  }
}

.pageTransition {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 99999999;
  display: none;
}

.safariTransition {
  position: fixed;
  top: -100%;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: none;
  background: var(--gradient);
}

.negativeMarginBtn {
  @include small {
    margin-top: -70px !important;
  }
}
