@import 'mixins';

.layout {
  display: flex;
  flex-direction: column;

  width: 80%;
  margin: 0 auto;

  --layout-padding: 0;
}

.layout.wide {
  width: 100% !important;
  --layout-padding: 10vw;
}

.layout.row {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.layout.row > * {
  flex: 1;
}

.layout.row .square {
  aspect-ratio: 1 / 1;
}

.layout.row.stretch {
  align-items: stretch;
}

.layout.row > *:not(:last-child) {
  margin-right: 3vw;
}

/* HD - */
@include hd {
  .layout {
    /*width: calc(100% - 4vw - 40px);*/
  }
}

/* FHD - */
@include fhd {
  .layout {
    width: 1400px;
    --layout-padding: calc(calc(100% - 1400px) / 2);
  }

  .layout.row > *:not(:last-child) {
    margin-right: 4vw;
  }

  .layout.wide {
    --layout-padding: calc(calc(100% - 1400px) / 2);
  }
}

/* 2K + */
@include qhd {
  .layout {
    width: 1600px;
    --layout-padding: calc(calc(100% - 1600px) / 2);
  }

  .layout.row > *:not(:last-child) {
    margin-right: 100px;
  }

  .layout.wide {
    --layout-padding: calc(calc(100% - 1600px) / 2);
  }
}

@include small {
  .layout {
    width: 100%;
    padding: 0 var(--tot-padding);
  }
  .layout.row {
    flex-direction: column;
  }
  .layout.row > *:not(:last-child) {
    margin-right: 0;
    margin-bottom: var(--tot-padding);
  }

  body {
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
  }
  body::-webkit-scrollbar {
    display: none;
  }
}
