$xs: 350px;
$sm: 800px;
$mid: 1100px;
$hd: 1400px;
$fhd: 1675px;
$qhd: 2000px;

@mixin xs {
  @media only screen and (width <= $xs) {
    @content;
  }
}

@mixin small {
  @media only screen and (width <= $sm) {
    @content;
  }
}

@mixin mid {
  @media only screen and (width > $sm) and (width <= $mid) {
    @content;
  }
}

@mixin normalmid {
  @media only screen and (width > $mid) {
    @content;
  }
}

@mixin normal {
  @media only screen and (width > $sm) {
    @content;
  }
}

@mixin hd {
  @media only screen and (width > $hd) {
    @content;
  }
}

@mixin fhd {
  @media only screen and (width > $fhd) {
    @content;
  }
}

@mixin qhd {
  @media only screen and (width > $qhd) {
    @content;
  }
}
